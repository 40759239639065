var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"notification-data-table",attrs:{"items":_vm.getNotifications,"footer-props":_vm.footers,"hide-default-header":"","disable-filtering":"","disable-sort":"","mobile-breakpoint":"0","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.itemsPerPage},on:{"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('ListItemNotification',{staticClass:"mx-1",class:index === 0
            ? 'mb-4'
            : index === _vm.getNotifications.length - 1
            ? 'mt-4 mb-2'
            : 'my-4',attrs:{"notification":item,"read-status":item.is_read !== 0}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }