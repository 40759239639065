<template>
  <v-container class="mt-n3">
    <div>
      <v-card flat class="card-component" color="transparent">
        <v-card-title class="font-weight-bold card-title-text justify-center">
          <span>Cross Pointアプリから</span>
          <span>退会しますか？</span>
        </v-card-title>
        <div class="text-center card-subtitle-text mt-2">
          <div>編集した情報は</div>
          <div>このアプリから削除されます。</div>
        </div>
        <v-card-actions class="justify-center mt-8">
          <v-btn block color="primary" :loading="loading" @click="withdraw">{{
            $t("proceed_with_withdraw")
          }}</v-btn>
        </v-card-actions>
        <p class="text-center mt-4">
          <v-btn color="#6179A9" text @click="cancelWithdraw">{{
            $t("cancel_withdraw")
          }}</v-btn>
        </p>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Withdraw",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async withdraw() {
      this.loading = true;
      this.$store
        .dispatch("USER_WITHDRAW_ACCOUNT", { id: this.$store.getters.user.id })
        .then(() => {
          this.$store.dispatch("AUTH_LOGOUT");
        })
        .finally(() => (this.loading = false));
    },
    cancelWithdraw() {
      this.$router.push({ name: "Top" });
    }
  }
};
</script>

<style scoped>
.card-component {
  margin-top: 102px;
}
.card-title-text {
  font-size: 20px;
}
.card-subtitle-text {
  font-size: 14px;
}
</style>
