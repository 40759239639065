<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div
          class="font-weight-regular main--text lighten-3 text-heading-1 text-center mt-2"
        >
          {{ notificationSubTitle }}
        </div>
      </v-col>
      <v-col>
        <NotificationList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationList from "@/components/layout/notifications/NotificationList";
import { mapGetters } from "vuex";
export default {
  name: "Notifications",
  components: { NotificationList },
  computed: {
    ...mapGetters(["getNotificationListTitle"]),
    notificationTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("notifications");
      } else {
        return this.getNotificationListTitle;
      }
    },
    notificationSubTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("notifications_subtitle");
      } else {
        return this.$t("notifications_subtitle_related");
      }
    }
  }
};
</script>

<style scoped></style>
