<template>
  <div>
    <v-data-table
      :items="getNotifications"
      :footer-props="footers"
      hide-default-header
      disable-filtering
      disable-sort
      mobile-breakpoint="0"
      :items-per-page="itemsPerPage"
      :server-items-length="itemsPerPage"
      @update:options="updateTable"
      class="notification-data-table"
    >
      <template v-slot:item="{ item, index }">
        <ListItemNotification
          class="mx-1"
          :notification="item"
          :class="
            index === 0
              ? 'mb-4'
              : index === getNotifications.length - 1
              ? 'mt-4 mb-2'
              : 'my-4'
          "
          :read-status="item.is_read !== 0"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ListItemNotification from "@/components/layout/notifications/partials/ListItemNotification";
import { mapGetters } from "vuex";
export default {
  name: "NotificationList",
  components: { ListItemNotification },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      initialLoad: true
    };
  },
  created() {
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getNotifications", "getNotificationsPagination"]),
    footers() {
      return {
        itemsPerPageText: this.$t("items_per_page"),
        itemsPerPageOptions: [this.itemsPerPage],
        pagination: {
          page: 1,
          itemsPerPage: this.itemsPerPage,
          pageStart: this.getNotificationsPagination.records_from - 1,
          pageStop: this.getNotificationsPagination.records_to,
          pageCount: this.getNotificationsPagination.total_pages,
          itemsLength: this.getNotificationsPagination.records_total
        },
        showCurrentPage: true
      };
    }
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e.page);
    },
    getDataFromApi(page) {
      this.loading = true;

      let params = {
        paginate: this.itemsPerPage,
        page: page,
        sort: "post_date",
        order: "desc"
      };

      if (this.$route.params.category_id != undefined) {
        params.category_id = this.$route.params.category_id;
      }

      if (this.$route.params.tag_id != undefined) {
        params.tag_id = this.$route.params.tag_id;
      }

      this.$store
        .dispatch("NOTIFICATION_GET", {
          params: params
        })
        .then(() => {
          if (this.initialLoad) this.initialLoad = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}

.theme--light.v-data-table >>> .v-data-footer {
  border: none !important;
}
.notification-data-table {
  width: 100%;
}
::v-deep .v-data-footer__icons-before {
  margin-left: 14px !important;
}
</style>
